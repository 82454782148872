import React from 'react'
import { css } from '@emotion/core'
import Layout from '../components/layout'
import SEO from '../components/seo'
import GatsbyImage from '../components/gatsby-image'
import FetchedForm from '../components/fetched-form'

export default function Form(props) {
  const { name, url, image, ring, rotate, date, email } = props?.pageContext

  return (
    <Layout>
      <SEO title={`Gift with Purcahse | ${name}`}/>
      {image && <GatsbyImage image={image}/>}
      <div style={{width: '100%', height: '1rem'}}/>
      <main css={styles({ring, rotate})}>
          <h1>Claim Your Free Gift With Purchase</h1>
      </main>
      <FetchedForm url={url} ring={ring} rotate={rotate} date={date} email={email}/>
    </Layout>
  )
}

const styles = ({ring, rotate}) => css`
  h1 {
    color: ${rotate ?? ring ?? 'white'};
  }
`
