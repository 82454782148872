import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'

export default function Spinner(props) {
    return (
        <Div {...props}/>
    )
}

const Div = styled.div(props => {
    return css`
        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
        border: 16px solid ${props.ring || 'white'};
        border-top: 16px solid ${props.rotate || 'blue'};
        border-radius: 50%;
        width 120px;
        height: 120px;
        animation: spin 2s linear infinite;
        margin: 0 auto;
    `
})