import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import Img from 'gatsby-image'
import { getFluidGatsbyImage } from 'gatsby-source-sanity'
import config from '../../../config.json'

export default function GatsbyImage({image}) {
    return (
        <Div>
            <Img fluid={getFluidGatsbyImage(
                image?.asset?._ref,
                { maxWidth: 5000, quality: 100 },
                config
            )}/>
        </Div>
    )
}

const Div = styled.div(props => {
    return css`
        max-width: 500px;
        padding 0 3rem;
        margin: auto;
    `
})