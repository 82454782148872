import React, { useState, useEffect } from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import axios from 'axios'
import jwt from 'jsonwebtoken'
import md5 from 'md5'
import 'formdata-polyfill'
import Spinner from './spinner'

export default function FetchedForm({ url, ring, rotate, date, email }) {
    const [html, setHtml] = useState(null)
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)

    const toBase64 = file => new Promise((resolve, reject) => {
        if ( /\.(jpe?g|png|gif|pdf)$/i.test(file.name) ) {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
        } else {
            reject(`Must be correct type: ${file.name}`)
        }
    })

    async function mount() {
        const env = `prod`
        try {
            const formRes = await axios({
                method: `POST`,
                url: `https://m570gzyn6h.execute-api.us-east-1.amazonaws.com/${env === `test` ? `staging` : `production`}`,
                data: {
                    url: `https://giftwp${env === `test` ? `-test` : ``}.escsportsapi.com/form/load`,
                    data: {
                        url: url,
                    }
                },
            })

            // setHtml(formRes.data.form || `<div class=\'error\'>
            //     Either the form failed to load or there is not currently a free gift promotion for this brand.
            //     <div style={{width: '100%', height: '1rem'}}/>
            //     <button onclick=\'document.location.reload()\'>Reload</button>
            // </div>`)

            setHtml((formRes.data.form ?? `<div class=\'error\'>
                Either the form failed to load or there is not currently a free gift promotion for this brand.
                <div style={{width: '100%', height: '1rem'}}/>
                <button onclick=\'document.location.reload()\'>Reload</button>
            </div>`) || `<div class=\'error\'>
                There is not currently a free gift promotion for this brand.
            </div>`)

            let form = document.querySelector('form')
            if (form) {
                form.addEventListener('submit', async e => {
                    e.preventDefault()

                    let fd = new FormData(form)
                    fd.set('site', 'escaladesports')
                    fd.set('form_id', form.id)
                    if (fd.get('ccnum')) {
                        let creditCardInfo = {
                            cc: {
                                number: fd.get('ccnum'),
                                expire: {
                                    month: fd.get('ccmon'),
                                    year: fd.get('ccyear')
                                },
                                code: fd.get('cccode')
                            }
                        }
                        const jwtToken = jwt.sign(creditCardInfo, md5(fd.get('site')))
                        fd.set('jwt', jwtToken)
                        fd.delete('cccode')
                        fd.delete('ccmon')
                        fd.delete('ccnum')
                        fd.delete('ccyear')
                    }

                    let jsonData = {}
                    for (let [key, value] of fd){
                        if(value instanceof File && typeof value.name === `string`){
                            if(value.name){
                                const img64 = await toBase64(value)
                                const ext = img64.substring(img64.indexOf('/') + 1, img64.indexOf('base64'))
                                if(ext.length) {
                                    jsonData[key] = img64
                                    jsonData[`${key}_ext`] = ext
                                }
                            }
                        } else {
                            jsonData[key] = value
                        }
                    }

                    console.log(`JSON: %o`, jsonData)
                    setLoading(true)
                    try {
                        const postRes = await axios({
                            method: `POST`,
                            url: `https://m570gzyn6h.execute-api.us-east-1.amazonaws.com/production/`,
                            data: {
                                url: `https://giftwp.escsportsapi.com/submission/save`,
                                data: jsonData
                            },
                        })

                        setLoading(false)
                        if (postRes.status === 200 && postRes.data.sent.success) {
                            setMessage('Successfully submited your request.')
                        } else {
                            setMessage(`Error: ${postRes.data.errorMessage}`)
                        }
                    } catch (e) {
                        setLoading(false)
                        setMessage(`Error: ${e}`)
                    }
                })
            }
        } catch (e) {
            setMessage(`Error: ${e}`)
        }
    }

    useEffect(() => {
        mount()
    }, [])

    return (
        <>
            {loading ?
            <div>
                <Spinner ring={ring} rotate={rotate}/>
            </div>
            :
            <Div rotate={rotate}>
                {message ? (
                    <div className="message">{message}</div>
                ) : html ? (
                    <>
                        <div
                            key={1}
                            dangerouslySetInnerHTML={{ __html: html }}
                        />
                        <div
                            key={2}
                            style={{
                                fontSize: '.8em',
                                textAlign: 'center',
                                margin: '35px 0 50px 0'
                            }}
                        >
                            {date && <p>* Offer only valid until {date}</p>}
                            {email &&
                            <>
                                If you are having trouble or have questions, please email us at{' '}
                                <a
                                    href={`mailto:${email}`}
                                    style={{ textDecoration: 'none' }}
                                >
                                    <span style={{ color: rotate ?? '#ef4c23' }}>
                                        {email}
                                    </span>
                                </a>
                            </>}
                        </div>
                    </>
                ) : (
                    <Spinner ring={ring} rotate={rotate}/>
                )}
            </Div>}
        </>
    )
}

const Div = styled.div(props => {
    return css`
        max-width: 1024px;
        padding 0 3rem;
        margin: auto;
        .message, .error {
            text-align: center;
        }
        .error {
            display: flex;
            flex-direction: column;
            align-items: center;
            button {
                cursor: pointer;
                border: 1px solid ${props.rotate ?? props.ring ?? 'white'};
                border-radius: 5px;
                width: 70px;
                height: 30px;
            }
        }
        form input,
        select,
        textarea {
            width: 100%;
            box-sizing: border-box;
            font-size: 14px;
            cursor: default;
            display: block;
            background: #f6f7f6;
            min-height: 35px;
            margin: 5px auto 20px auto;
            position: relative;
        }
        textarea {
            height: 75px;
        }
        form select {
            padding: 10px 15px;
        }
        input[type='submit'] {
            color: ${props.rotate ?? '#f6f7f6'};
            background: black;
            border: 1px solid ${props.ring ?? 'white'};
            border-radius: 5px;
            width: auto;
            height: 41px;
            padding: 10px 30px;
            font-weight: 700;
            cursor: pointer;
            font-family: inherit;
            font-size: 1em;
            margin: 5px 0 20px 0;
        }
        input[type='file']{
            background: transparent;
        }
        header {
            margin-top: 50px;
            text-align: center;
        }
        ul {
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
        ul > li {
            padding: 20px;
        }
        ul > li > a {
            text-decoration: none;
            color: white;
            font-weight: bold;
            transition: 0.4s;
            cursor: pointer;
        }
        ul > li > a:hover {
            color: lightblue;
        }
    `
})